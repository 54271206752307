import dayjs from "dayjs";
import ReactMarkdown from "react-markdown";
import request from "graphql-request";
import React, { useEffect, useState } from "react";
import "../assets/css/blog.scss";
import Layout from "../components/layout";
import Loading from "../components/loading";
import clock from "../images/black-clock.svg";
import ReadTime from "../lib/readTime";
import SingleBlogCard from "../components/singleBlogCard";
import ShowProvider from "../components/showProvider";
import { Link } from "gatsby-plugin-react-intl";
import { useLocation } from "@reach/router";
import fbIcon from "../images/fb-icon.svg";
import lnIcon from "../images/linkedin-icon.svg";

import twitterIcon from "../images/twitter-icon.svg";
import shareIcon from "../images/share-icon.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Head from "../components/head";
import SEO from "../components/seo";
function Blog({ pageContext }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [anothers, setAnothers] = useState([]);
  const [contact, setContact] = useState([]);
  const [seo, setSeo] = useState({});
  const [percentSeen, setPercentSeen] = useState(0);
  const location = useLocation();
  const fetch = async () => {
    const res = await request(
      process.env.GATSBY_GRAPHQL_URL,
      `{
        articles (locale: "${pageContext.intl.language}",limit: 3,where:{slug_ne:"${pageContext.slug}"}) {
          title
          description
          content
          image {
            url
          }
          updated_at
          slug
          articles_category{ 
            category
          }
        }
        articlesConnection(locale: "${pageContext.intl.language}",where:{slug:"${pageContext.slug}"}){
          values {
            title
            content
            updated_at
            image{
              url
            }
            articles_category{
              category
            }
          }
        }
      contact{
        contact_single {
          img {
            url
          }
          url
        }
      }
      }
      `
    );
    return res;
  };
  const handleScroll = (e) => {
    let scroll = window.scrollY;
    var maxY =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    setPercentSeen((scroll / maxY) * 100);
  };
  useEffect(() => {
    fetch().then((items) => {
      setContact(items.contact.contact_single);
      setData(items.articlesConnection.values[0]);
      setAnothers(items.articles);
      setLoading(false);
    });

    window.addEventListener("scroll", handleScroll);
  }, []);

  const strapiHost = process.env.GATSBY_API_URL;
  return (
    <>
      <SEO
        seo={{
          metaTitle: pageContext.seo.metaTitle,
          metaDescription: pageContext.seo.metaDesc,
        }}
      />
      {loading ? (
        <Loading />
      ) : (
        <ShowProvider>
          <div className="currentScroll" style={{ width: `${percentSeen}%` }} />
          <Layout initial={false} nav={false} contactUs={false}>
            <div className="singlePost">
              <div className="singlePost__socials">
                {[
                  {
                    icon: fbIcon,
                    link:
                      "http://www.facebook.com/sharer.php?u=" + location.href,
                  },
                  {
                    icon: twitterIcon,
                    link: "http://twitter.com/share?url=" + location.href,
                  },
                  {
                    icon: lnIcon,
                    link:
                      "https://www.linkedin.com/shareArticle?mini=true&amp;url=" +
                      location.href,
                  },
                  {
                    icon: shareIcon,
                    link: "#",
                    onClick: () => {
                      navigator.clipboard.writeText(location.href);
                    },
                  },
                ].map(({ icon, link, onClick }, key) => (
                  <a
                    className="singlePost__socials-icon"
                    href={link}
                    target={link !== "#" ? "_blank" : ""}
                    onClick={onClick}
                    key={key}
                  >
                    <img src={icon} />
                  </a>
                ))}
              </div>
              <div className="singlePost__content">
                <h1 className="post__hero-text">{data.title}</h1>
                <div className="post__details">
                  <img src={clock} />
                  <p className="post__details-text">
                    {ReadTime(data.content)} min |{" "}
                    {data.articles_category.category} |{" "}
                    {dayjs(data.updated_at).format("DD.MM.YYYYr")}
                  </p>
                </div>
                <img
                  src={`${strapiHost}${data.image.url}`}
                  className="post__hero-image"
                />

                <div className="post__main-content">
                  <ReactMarkdown>{data.content}</ReactMarkdown>
                </div>
              </div>
              <div className="singlePost__featured">
                <h2 className="singlePost__featured-title">
                  Popularne artykuły
                </h2>
                <div className="singlePost__featured-posts">
                  {anothers.map(
                    (
                      { title, updated_at, articles_category, content },
                      key
                    ) => {
                      return (
                        <div className="singlePost__featured-post" key={key}>
                          <a
                            href="#"
                            className="singlePost__featured-post-title"
                          >
                            {title}
                          </a>
                          <p className="singlePost__featured-post-text">
                            {ReadTime(content)} min |{" "}
                            {articles_category.category} |{" "}
                            {dayjs(updated_at).format("DD.MM.YYYYr")}
                          </p>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          </Layout>
        </ShowProvider>
      )}
    </>
  );
}

export default Blog;
